import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class EndUserLicenseAgreement extends React.Component {

  render(){

    return(
      <Layout>

        <SEO title="End User License Agreement"/>
        <Link to="/" className="logo">Super!com</Link>
        
        <div className="text-page">
          <div className="center">

            <h2 className="text-page__title">EULA</h2>

            <h3 className="text-page__subtitle">End User License Agreement</h3>

            <p className="text-page__paragraph -title">
              <span className="text-page__callout">
                Summary
              </span>
              Legally binding text
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                This text explains how you can use digital content offered by Super.Com via third parties stores and marketplaces.
              </span>
              This End User License Agreement (“EULA”) is a legal document that governs the relationship between you and SUPER DOT COM LTD, with its registered office at 156A Burnt Oak Broadway, Edgware, Middlesex, HA8 0AX, United Kingdom, company number  11614551 (the “Licensor”, or “Super.Com”).
            </p>

            <p className="text-page__paragraph">
              Please read this EULA carefully before purchasing and installing game software offered by Super.Com, since the EULA explains your rights and obligations as an end user (“Licensee”, or “You”).
            </p>

            <p className="text-page__paragraph -title">
              <span className="text-page__callout">
                In this section we explain the basic conditions necessary to accept the EULA.
              </span>
              1.    GENERAL PROVISIONS.
            </p>

            <p className="text-page__paragraph">
              1.1.   Super.Com. The Licensor is a publisher and distributor of certain video games, virtual items, software, manuals, and other digital content.   Such video games, virtual items, software, etc. are referred to in this EULA as “Game Software,” and the personal rights to use this Game Software are referred to as the "License.”
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                You must reach a certain age to use PaySuper. This age may vary from country to country. You may also need the consent of a parent or guardian to accept the EULA.
              </span>
              1.2.   Legal Capacity. Super.Com is authorized to distribute the Game Software and grant Licenses to end users pursuant to license agreements made between the Licensor and respective game developers and copyright owners.
            </p>

            <p className="text-page__paragraph">
              By accepting this EULA, you represent that you have full legal capacity to enter into legally binding contracts in the country of your domicile, residence, and citizenship and meet applicable age restrictions. If you are considered a minor in your country, you must have your parent or legal guardian's consent to use Super.Com and accept this EULA. In any case, you must not accept this EULA if you are under the age of thirteen, since Super.Com is not intended for minors under thirteen and Super.Com will not knowingly collect personal data from minors under the age of thirteen.
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Please read the EULA carefully, because it explains your rights and obligations as an end user.
              </span>
              1.3.   Acknowledgement By accepting this EULA, you confirm that:
            </p>

            <ul className="text-page__list -padding-right">
              <li data-marker="(i)">you have carefully read this Agreement, and</li>
              <li data-marker="(ii)">fully understand the terms and conditions contained herein, and</li>
              <li data-marker="(iii)">you enter into this Agreement voluntarily and intentionally.</li>
            </ul>

            <p className="text-page__paragraph">
              1.4.   Acceptance. By downloading, installing, opening, copying, and/or otherwise using the Game Software, you agree to be bound by the terms of this EULA.
            </p>

            <p className="text-page__paragraph -title">
              <span className="text-page__callout">
                In this section we explain the key elements of the EULA.
              </span>
              2.      SCOPE OF THE AGREEMENT
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                When you make a purchase, you receive a non-exclusive license to use the game software. However, you will not acquire copyright ownership.
              </span>
              2.1.   Grant of License. Subject to your unconditional acceptance of and compliance with this Agreement, Super.Com grants you a non-exclusive, non-transferable, non-sublicensable, revocable, and personal limited license to or use one copy of the applicable Game Software on a single game platform (e.g. personal computer, gaming console, or mobile device) for non-commercial purposes.
            </p>

            <p className="text-page__paragraph">
              2.2.   Ownership. Super.Com, respective game developers and copyright owners reserve all rights, title, and interest in the Game Software not expressly granted to you pursuant to this EULA. The License under this EULA confers no title or ownership of the Game Software.
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Your license may be geographically restricted or limited by digital stores, game developers and copyright owners.
              </span>
              2.3.   Territory. The Licensor hereby notifies you, and you acknowledge that the availability of the Game Software and its features may vary from country to country, and certain Game Software published by Super.Com may not be available in your country.
            </p>

            <p className="text-page__paragraph">
              2.4.   Third-Party Terms and Conditions. Your purchase and use of the Game Software may be governed by the additional terms and conditions of end user license agreements between you and digital stores / marketplaces operated by third parties. Acceptance of such terms and conditions may be required in order to purchase access, download, install, and use the Game Software.
            </p>

            <p className="text-page__paragraph">
              Terms and conditions established by digital stores and marketplaces may include additional restrictions and/or impose upon you additional obligations as compared to this EULA.
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Please read the descriptions of the games carefully.
              </span>
              2.5.   Description of Game Software. Prior to purchasing the License, you should carefully read the description of the Game Software, including minimum technical requirements. You may send questions and inquiries regarding the descriptions of the Game Software via the support service or email.
            </p>

            <p className="text-page__paragraph -title">
              3.      GAME SOFTWARE DELIVERY
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Respective digital stores and marketplaces or we will provide you with license keys, codes, or links to access the game software after the competition of the purchase.
              </span>
              3.1.   Access to Game Software. Subject to the terms and conditions of digital stores and marketplaces operated by third parties, Super.Com grants you a License to use the Game Software. Depending on the type of the Game Software, you will be able to download and install the Game Software on your device, activate and use certain features and virtual items, or otherwise obtain access to the Game Software. The Game Software will be considered to be duly supplied at the moment when the Game Software is made accessible to you for download or use irrespective of the time when you actually downloaded, installed, consumed, exchanged, or otherwise started using it.
            </p>

            <p className="text-page__paragraph">
              3.2.   Third-Party Services. You are responsible for any access or data charges incurred from third parties (such as your internet service provider or mobile operator) in connection with downloading, accessing, and using the Game Software.
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Some software may require updates.
              </span>
              3.3.   Updates. Game Software may need to be updated from time to time, for example, for bug fixes, enhanced functions, and new versions. You may be required to install updates to begin or continue using the Game Software. You agree that such updates may be downloaded and installed on your devices automatically without additional consent or notice. You also acknowledge that updates may affect gameplay, graphics, performance, virtual items, characters, and other features of the Game Software. However, nothing in this Clause shall be construed as the Licensor’s obligation or warranty to develop updates, patches, or other modifications of the Game Software.
            </p>

            <p className="text-page__paragraph -title">
              4.      RESTRICTIONS
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Using the games outside the scope of your license is not permitted. This may result in the revocation of the license and legal liability.
              </span>
              4.1.   Limited License. You must not use the Game Software in any way or form not expressly permitted under this Agreement. In particular, you may not:
            </p>

            <ul className="text-page__list -padding-right">
              <li data-marker="(a)">
                <span className="text-page__callout">
                  Game Software may be protected against unauthorized use by technological measures.
                </span>
                copy, reproduce, distribute, modify, reverse engineer, decompile, create derivative works of the Game Software or any parts or elements thereof or attempt to do so;
              </li>
              <li data-marker="(b)">remove any notices or labels from the Game Software or any parts or elements thereof. You may not reverse engineer the Game Software or extract the source code or data from the Game Software or attempt to do so;</li>
              <li data-marker="(c)">use the Game Software at a computer gaming center or any other location-based site;</li>
              <li data-marker="(d)">make a copy of the Game Software available on a network for use or download by multiple users;</li>
              <li data-marker="(e)">cheat or utilize any unauthorized robot, spider, or other program in connection with any online features of the Game Software;</li>
              <li data-marker="(f)">lease, sell, rent, offer for sale or otherwise attempt to transfer the Game Software or any parts or elements thereof, including virtual items or virtual currency.</li>
            </ul>

            <p className="text-page__paragraph">
              4.2.   Non-Transferable License. You may not sublicense, assign, or transfer the License to the Game Software to any third parties.
            </p>

            <p className="text-page__paragraph">
              4.3.   Technological Measures. The Game Software may be protected by technological measures designed to prevent or restrict actions related to the Game Software not authorized by this EULA and the terms and conditions established by game developers and copyright owners. You must not attempt to, assist in, authorize, or encourage others to circumvent, disable, or defeat any technological measures of the Game Software.
            </p>

            <p className="text-page__paragraph">
              You acknowledge and agree that technological measures may conflict with third-party software, including software emulating user activity, and result in the improper functioning of such software. In order to avoid conflicts, you may uninstall the Game Software from your device.
            </p>

            <p className="text-page__paragraph">
              4.4.   Other Restrictions. You shall not purchase or use Licenses to the Game Software on behalf or in favor of third parties that support countries to which encryption or software exports are at the time of exportation restricted by governmental or international authorities. You also represent and warrant that you are not located in, under the control of, or a national or resident of any such prohibited country.
            </p>

            <p className="text-page__paragraph -title">
              5.      PAYMENTS
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Super.Com publishes game through the digital stores or marketplaces operated by third parties. You purchase the license from such digital stores or marketplaces, not from Super.Com directly. For this reason, please, contact a respective digital store, if you encounter any troubles with payment transactions.
              </span>
              5.1.   Digital Stores. Game Software may be distributed by Super.Com through digital stores or marketplaces operated by third parties (“Digital Stores”). This Agreement is solely between you and Super.Com, and not with any such Digital Stores (“Digital Stores”).
            </p>

            <p className="text-page__paragraph">
              5.2.   Fees and Prices. Fees and prices for Licenses to the Game Software are displayed through the Digital Stores and subject to change at any time prior to purchase.
            </p>

            <p className="text-page__paragraph">
              5.3.   Payments. All payment transactions are administered by the Digital Stores, not Super.Com. Super.Com expressly disclaims any liability for any such transactions, and you agree that your sole remedy regarding all payments is from or through such Digital Stores.
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Refund policies are established by digital stores and marketplaces. Terms of refunds may vary.
              </span>
              5.4.   No refund. Unless otherwise expressly set forth in the terms and conditions of Digital Stores, all payments and Licenses are final and non-refundable in whole or in part, regardless of the payment method. However, the Digital Stores may establish various refund policies and you may request a refund according to such policies.
            </p>

            <p className="text-page__paragraph -title">
              6.   USER GENERATED CONTENT
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Intellectual property rights subsisting in the content created by you belong to you. At the same time, you permit Super.Com to use your content as described in this Section.
              </span>
              6.1.   User Generated Content. The following terms apply in relation to content (e.g. text, screenshots, let’s play videos, streams, etc.), which you make available either to Super.Com or to other persons, as well as to any other content which you create using the Game Software ("User Generated Content").
            </p>

            <p className="text-page__paragraph">
              6.2.   License. In exchange for use of the Game Software, and to the extent that your User Generated Content through use of the Software give rise to any intellectual property rights, you hereby grant Super.Com an exclusive, perpetual, irrevocable, fully transferable and sub-licensable worldwide right and license to use the User Generated Content.
            </p>

            <p className="text-page__paragraph">
              6.3.   Scope. The license to the User Generated Content includes the rights of Super.Com to reproduce, copy, adapt, modify, perform, display, publish, broadcast, transmit, or otherwise communicate to the public by any means whether now known or unknown and distribute your User Generated Content without any further notice or compensation to you of any kind for the whole duration of protection granted to intellectual property rights by applicable laws.
            </p>

            <p className="text-page__paragraph">
              6.4.   Restrictions. If the User Generated Content involves any video, audio, graphics, text, gameplay, characters, or other elements of the Game Software, you may use and share the User Generated Content for personal enjoyment only. It forbidden to use such User Generated Content for any other purposes including any commercial purposes, use in third party games, services or standalone products of any kind. If you have any doubts regarding the use of the User Generated Content, please contact us.
            </p>

            <p className="text-page__paragraph">
              6.5.   Moral rights. To the maximum extent permitted by the applicable laws, you waive any moral rights of paternity, publication, reputation, or attribution with respect to the User Generated Content.
            </p>

            <p className="text-page__paragraph">
              6.6.   Term. This Section survives any termination of this EULA.
            </p>

            <p className="text-page__paragraph -title">
              7.      REPRESENTATIONS AND WARRANTIES
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                We make legally binding promises (“representations and warranties”) explicitly stated in this Section. However, we do not make any other representations or warranties relating to digital stores and marketplaces operated by third parties.
              </span>
              7.1.   Licensor’ Representations and Warranties. The Licensor represents and warrants that it has the legal capacity to enter into this EULA and grant you the right and license to use the Game Software.
            </p>

            <p className="text-page__paragraph">
              7.2.   No other Representations and Warranties. To the maximum extent permitted by applicable law, the Game Software, Super.Com support services, and elements thereof are provided on an “as is” basis. Super.Com disclaims and does not provide any express, implied, or statutory warranties or guarantees, except as expressly set forth in Section 7, including warranties of merchantability, satisfactory quality, fitness for a particular purpose, non-infringement on third-party rights, and warranties arising out of the course of transactions, usage, or practice. The Licensor does not warrant that the operation of the Game Software and its elements will be uninterrupted or remain free from errors, bugs, hacking, interference, or losses and does not warrant the comparability of the Game Software with any other software.
            </p>

            <p className="text-page__paragraph">
              7.3.   Personal Requirements. The Licenses and Game Software published by Super.Com are not custom-made. Super.Com does not warrant that the Game Software, Super.Com support services, and elements thereof will meet your personal requirements and expectations.
            </p>

            <p className="text-page__paragraph">
              7.4.   Support Service. Super.Com does not provide any warranties that the support service can and will solve all issues, bugs, and issues or answer certain questions relating to the Game Software, or handle your request within a definite time. The support service’s feedback is of a recommendatory nature. It is your responsibility and choice to follow or decline the recommendations given by the support service, and you will bear all liability for any risks resulting from this choice and decision.
            </p>

            <p className="text-page__paragraph -title">
              <span className="text-page__callout">
                Since our games are not custom-made and provided on “as is” basis, liability of Super.Com is limited.
              </span>
              8.   LIMITATION OF LIABILITY
            </p>

            <p className="text-page__paragraph">
              8.1.   Personal Injury. The Licensor is liable for direct loss with respect to harm to health, body, or life, as well as for intentional harm caused by the Licensor, or in other cases pursuant to mandatory rules of applicable law.
            </p>

            <p className="text-page__paragraph">
              8.2.   Limitation of Liability. To the maximum extent permitted by applicable law, the Licensor, its affiliates, officers, and employees are not liable for any direct, indirect, incidental, consequential, special, exemplary, punitive, or other losses arising from or relating in any way to the Game Software Super.Com support services, and elements thereof. This limitation applies to any type of claim arising from or related to this Agreement, whether based on a contract, tort, statute, or otherwise.
            </p>

            <p className="text-page__paragraph">
              8.3.   Amount Limitation. In any case, but to the maximum extent permitted by applicable law, the maximum liability of the Licensor for all damages, losses, and causes of action, whether in the form of a contract, tort, or otherwise, shall be the total amount you paid for the Game Software over the 90-day period ending on the date when an accident or event resulting from the Licensor’s liability occurred.
            </p>

            <p className="text-page__paragraph">
              8.4.   Actions Beyond the Licensor’s Control. The Licensor, its affiliates, officers, and employees shall not be liable for any harm or losses arising due to your actions, omissions, or breach of the EULA, as well as such acts of third parties or other actions beyond the Licensor’s control.
            </p>

            <p className="text-page__paragraph -title">
              <span className="text-page__callout">
                This section deals with laws, regulations, and legal proceedings.
              </span>
              9.   GOVERNING LAW AND DISPUTE RESOLUTION
            </p>

            <p className="text-page__paragraph">
              9.1.   Governing Law. This Agreement and any dispute, claim, or obligation (whether contractual or non-contractual) arising out of or in connection with it or its subject matter or formation shall be governed by the laws of the Republic of Cyprus without regard to its "conflict of laws" principles.
            </p>

            <p className="text-page__paragraph">
              9.2.   Out-of-Court Settlement. You and the Licensor shall first attempt to resolve any disputes and claims out of court for at least thirty (30) days before initiating a judicial procedure. Out-of-court settlement will commence upon the receipt of written notice from one party to the other. Such notice shall include the full name and contact information of the complaining party, describe the nature and basis of the claim or dispute, and envisage a specific recompense.  The Licensor may send notices to your email address. You shall send notices to the Licensor’s registered office.
            </p>

            <p className="text-page__paragraph">
              9.3.   Venue. Without prejudice to applicable consumer protection laws, the parties to this Agreement shall submit all disputes and claims arising out of or in connection with it or its subject matter or formation to the jurisdiction of the courts competent within the territory of the Licensor’s headquarter, which is located in Limassol, Republic of Cyprus.
            </p>

            <p className="text-page__paragraph">
              9.4.   ODR. If you habitually reside within the European Union, the Licensor hereby informs you of the existence of the Online Dispute Resolution (ODR) platform and the possibility of using the ODR platform to resolve any disputes or claims arising from this EULA. The ODR platform's website can be found at https://ec.europa.eu/consumers/odr.
            </p>

            <p className="text-page__paragraph">
              This Clause shall not be construed as the Licensor’s submission to the ODR platform's jurisdiction and commitment to use the ODR platform to resolve disputes and claims arising from this EULA. In each case, the submission of the dispute or claim to the ODR platform is subject to your and the Licensor’s explicit agreement.
            </p>

            <p className="text-page__paragraph">
              9.5.   Statutory Rights. This Section shall not be construed as depriving you of the protection afforded by provisions that cannot be derogated by agreement pursuant to the law of the country of your habitual residence.
            </p>

            <p className="text-page__paragraph -title">
              10.    NOTICES OF COPYRIGHT INFRINGEMENT
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                We respect the intellectual property of others. If anything is wrong, please send us a notice as described in this section.
              </span>
              10.1.    Notices. We respect the intellectual property rights of third parties. If you believe that your copyrighted work has been used unlawfully in the Game Software or the Super.Com’s websites, you may notify us by sending a notice of an alleged infringement to our registered office.
            </p>

            <p className="text-page__paragraph">
              10.2.    Content of Notices. When submitting a notice of an alleged copyright infringement, please provide the following information: (a) your first name and last name, (b) mailing address, (c) email address, (d) name, address and contact information for the copyright owner you represent, or an indication that you are acting on your own behalf, (e) an identification and description of the allegedly infringed copyrighted work, (f) the URL of the authorized copy of the work or an indication that the work is not available online, (g) identification of the allegedly infringing material and the nature of the infringement, (h) the URL of the allegedly infringing material, (i) the following statements: "By submitting this notice and signing with my signature, I confirm that I have a good-faith belief that the use of the material in the manner cited of is not authorized by the copyright owner, its agent, or the law"; "I confirm that the information in this notification is accurate, and, under penalty of perjury, that I am authorized to act on behalf of the owner of an exclusive copyright that has allegedly been infringed"; and "I express my consent to process and transfer my personal data contained in this notice to Super Dot Com Ltd. and its affiliates and agree that the companies mentioned above will process my personal data for the period necessary to achieve the purpose of processing personal data and agree that my personal data may be disclosed to the alleged infringer”, and (j) the date and your handwritten signature.
            </p>

            <p className="text-page__paragraph -title">
              11.  TERM AND TERMINATION
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                You are free to remove our games and terminate the license at any time.
              </span>
              11.1.    Term of the EULA. This EULA shall remain in force and effect until terminated by you or the Licensor according to Section 11.
            </p>

            <p className="text-page__paragraph">
              11.2.    Account Deletion. You may unilaterally terminate the rights and licenses under this EULA without any explanation by uninstalling Game Software.
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                The license period may be limited for some digital content.
              </span>
              11.3.    Term of Licenses. Licenses to Game Software that you purchase will be effective for the period displayed at the time of the purchase or for as long as Super.Com has the right to make such Game Software available to you. In exceptional cases Super.Com may cease providing access to the Game Software that you have purchased earlier due to security issues or a breach of applicable terms or laws. You may be given notice of any such removal or cessation when possible.
            </p>

            <p className="text-page__paragraph">
              <span className="text-page__callout">
                In certain cases, Super.Com, digital stores and marketplaces may revoke Licenses and cease to provide access to game software.
              </span>
              11.4.    Termination by Super.Com. Super.Com may terminate this EULA, revoke the Licenses granted hereunder, and restrict access to Game Software in the following cases: (a) you have breached this EULA or any other binding terms and conditions, or (b) you have breached provisions of applicable law; (c) you have violated the rights or lawful interest of other users or third parties; (d) in other cases set forth in this EULA, any other binding terms and conditions, or applicable law.
            </p>

            <p className="text-page__paragraph">
              The Licensor may also terminate this EULA without any explanation and compensation after providing you 30 days’ prior notice. The termination notice may be provided by email or via the Super.Com website.
            </p>

            <p className="text-page__paragraph">
              11.5.    Termination by Digital Stores. Please note that the use of the Game Software purchased from Digital Stores may be subject to the terms and conditions established by the owners and operators of the Digital Stores. Such terms and conditions may include additional grounds for the termination of Licenses. Super.Com is not a party to the terms and conditions established by the Digital Stores and is, thus, not responsible for any policies, rules, actions, or omissions on the part of the Digital Stores.
            </p>

            <p className="text-page__paragraph -title">
              <span className="text-page__callout">
                This Section explains a number of supplementary legal issues.
              </span>
              12.  MISCELLANEOUS
            </p>

            <p className="text-page__paragraph">
              12.1.    Entire Agreement. This EULA sets out the entire agreement between you and Super.Com relating to the subject matter herein, and it supersedes and replaces any and all prior agreements and understandings between you and Super.Com. A person who is not a party to this EULA has no right to rely upon or enforce any part of the EULA.
            </p>

            <p className="text-page__paragraph">
              12.2.    Assignment. You may not transfer or assign any of the rights or Licenses granted to you hereunder without our prior written consent. Super.Com may transfer or assign all or some of the rights or responsibilities under this EULA to any third party without your consent and without providing you notice thereof.
            </p>

            <p className="text-page__paragraph">
              12.3.    Severability. In the event that any part of this EULA is declared unlawful, void, or unenforceable under any applicable local law or by a competent court, that provision shall be stricken out; meanwhile, the remainder of this EULA shall remain valid and enforceable.
            </p>

            <p className="text-page__paragraph">
              12.4.    Consumer Law. The terms and conditions of this EULA do not exclude, limit, or otherwise restrict consumer’ rights arising from the relevant provisions of applicable consumer law and may not be construed by anyone in this way.
            </p>

            <p className="text-page__paragraph">
              12.5.    Amendments. Super.Com may amend this EULA by publishing a new version thereof on its website. In the event that you have accepted this EULA before the publication of a revised version, the amendments will become effective on the 30th day following the publication date. You shall be deemed to have accepted such amendments by continuing to use Licenses or Game Software. In order to decline the revised version, you must cease using the Licenses and Game Software and terminate this EULA as envisaged in Section 11. The latest version of this EULA is available at any time on the our website.
            </p>

            <p className="text-page__paragraph">
              “We” and the “Licensor” in this EULA refer to: <br/>
              SUPER DOT COM LTD <br/>
              156A Burnt Oak Broadway, Edgware, Middlesex, HA8 0AX, United Kingdom, <br/>
              Company number 11614551
            </p>

            <p className="text-page__paragraph">
              Date: February 18, 2020
            </p>

          </div>
        </div>

      </Layout>
    );

  }

}

export default EndUserLicenseAgreement
